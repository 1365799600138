import { css } from 'styled-components'

export const Auth = css`
  .login-form,
  .forgot-password-form,
  .reset-password-form {
    width: 100%;
    max-width: 300px;
  }
  .login-form-button,
  .forgot-password-form-button,
  .reset-password-form-button {
    width: 100%;
  }
`
