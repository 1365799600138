// me
export const FETCH_ME = 'FETCH_ME'
export const PATCH_ME = 'PATCH_ME'

// prefectures
export const FETCH_PREFECTURES = 'FETCH_PREFECTURES'

// cities
export const FETCH_CITIES = 'FETCH_CITIES'

// medical
export const FETCH_MEDICALS = 'FETCH_MEDICALS'

// vacancies
export const FETCH_VACANCIES = 'FETCH_VACANCIES'

// facilities
export const POST_FACILITIES = 'POST_FACILITIES'
export const FETCH_FACILITIES = 'FETCH_FACILITIES'
