import { Global } from '@/styles/types'

import defaultTheme, { colors } from '@/styles/default'

const global: Global = {
  color: {
    bg: colors.background,
    bgNav: colors.main,
    bgNavActive: colors.main,
    bgBreadcrumb: colors.tertiary,
    bgCard: colors.white,
    bgImg: colors.white,
    text: colors.secondary,
    textNav: colors.white,
    textBreadCrumb: colors.secondary,
    divider: colors.stroke,
  },
  fontSize: defaultTheme.fontSizes.baseFontSize,
  boxShadow: defaultTheme.boxShadow.boxShadow,
}

export const lightTheme = {
  name: 'Light Theme',
  global,
  ...defaultTheme,
}

export default lightTheme
