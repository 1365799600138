import { css } from 'styled-components'

export const Layout = css`
  .layout {
    min-height: 100dvh;
  }

  .site-layout-content {
    flex: 1;
  }

  .main-content {
    height: calc(100dvh - 175px);
    overflow-y: auto;
  }

  .main-content-mobile {
    height: 100%;
    overflow-y: auto;
  }
`
