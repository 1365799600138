import { SET_VACANCY } from '@/store/actionTypes'
import { VacancyActions, VacancyState } from '@/store/models/vacancyModel'

const initialState: VacancyState = {
  vacancies: [],
}

export default (state = initialState, action: VacancyActions): VacancyState => {
  switch (action.type) {
    case SET_VACANCY:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return {
        ...state,
      }
  }
}
