import { all, fork } from 'typed-redux-saga'

import citySaga from './sagas/citySaga'
import facilitySaga from './sagas/facilitySaga'
import medicalSaga from './sagas/medicalSaga'
import prefectureSaga from './sagas/prefectureSaga'
import userSaga from './sagas/userSaga'
import vacancySaga from './sagas/vacancySaga'

export default function* rootSaga() {
  yield* all([
    fork(userSaga),
    fork(prefectureSaga),
    fork(citySaga),
    fork(medicalSaga),
    fork(vacancySaga),
    fork(facilitySaga),
  ])
}
