import { Layout } from 'antd'
import { Auth } from 'aws-amplify'
import { LayoutMenu } from '@/components/organisms/layout/LayoutMenu'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Outlet, useNavigate } from 'react-router-dom'
import { fetchMe } from '@/saga/actions/userAction'
import { getMeSelector } from '@/store/selectors/userSelector'

const { Content } = Layout

const RestrictedPage = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const me = useSelector(getMeSelector)
  const [userAttributes, setUserAttributes] = useState<any>()

  useEffect(() => {
    const f = async () => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      const attributes = await Auth.currentSession()
      setUserAttributes(attributes)
    }
    void f()
  }, [navigate])

  useEffect(() => {
    dispatch(fetchMe())
  }, [navigate])

  useEffect(() => {
    if (me.id && (!me.department || !me.kind || !me.occupation || !me.place)) {
      navigate('/profile')
    }
  }, [me])

  useEffect(() => {
    if (!userAttributes) return
    /* eslint-disable @typescript-eslint/no-unsafe-member-access */
    console.warn({
      idToken: userAttributes.idToken,
      refreshToken: userAttributes.refreshToken,
      accessToken: userAttributes.accessToken,
    })
    localStorage.setItem('jwtToken', userAttributes.idToken.jwtToken as string)
    /* eslint-enable */
    // console.warn({ sub: userAttributes.sub, email: userAttributes.email })
    dispatch(fetchMe())
  }, [userAttributes])

  if (!me.id) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>
  }

  return (
    <>
      <LayoutMenu />
      <Content className="site-layout-content">
        <Outlet />
      </Content>
    </>
  )
}

export default RestrictedPage
