import { FETCH_ME, PATCH_ME } from '@/saga/actionTypes'
import { FetchMe, PatchMe, PatchMePayload } from '@/saga/models/userModel'

export const fetchMe = (): FetchMe => ({
  type: FETCH_ME,
})

export const patchMe = (payload: PatchMePayload): PatchMe => ({
  type: PATCH_ME,
  payload,
})
