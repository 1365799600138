import { SET_MEDICAL } from '@/store/actionTypes'
import { MedicalActions, MedicalState } from '@/store/models/medicalModel'

const initialState: MedicalState = {
  medical: [],
}

export default (state = initialState, action: MedicalActions): MedicalState => {
  switch (action.type) {
    case SET_MEDICAL:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return {
        ...state,
      }
  }
}
