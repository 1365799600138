import { ExclamationCircleFilled, UserOutlined } from '@ant-design/icons'
import { Button, Col, Dropdown, Layout, MenuProps, Modal, Row, Space } from 'antd'
import akijoLogo from '@/assets/images/akijo-logo-horizontal.jpg'
import { Auth } from 'aws-amplify'
import Desktop from '@/components/templates/responsive/Desktop'
import { SyntheticEvent } from 'react'
import { Link } from 'react-router-dom'
import { useMobile } from '@/utils/useMobile'

const { Header } = Layout

const { confirm } = Modal

export const LayoutHeader = () => {
  const isMobile = useMobile()

  const execSignout = () => {
    confirm({
      title: 'サインアウト',
      icon: <ExclamationCircleFilled />,
      content: 'サインアウトします。よろしいですか？',
      onOk() {
        void Auth.signOut()
      },
    })
  }

  const handleSignout = (e: SyntheticEvent) => {
    e.preventDefault()
    execSignout()
  }

  const execDeleteAccount = () => {
    confirm({
      title: '退会',
      icon: <ExclamationCircleFilled />,
      content: '退会します。よろしいですか？',
      onOk() {
        void Auth.deleteUser()
      },
    })
  }

  const handleDeleteAccount = (e: SyntheticEvent) => {
    e.preventDefault()
    execDeleteAccount()
  }

  const items: MenuProps['items'] = [
    {
      label: <Link to="/profile">プロフィール設定</Link>,
      key: '0',
    },
    {
      type: 'divider',
    },
    {
      label: (
        <Link to="!#" onClick={handleSignout}>
          サインアウト
        </Link>
      ),
      key: '1',
    },
    {
      label: (
        <Link to="!#" onClick={handleDeleteAccount}>
          退会
        </Link>
      ),
      key: '2',
    },
  ]

  return (
    <Header style={{ position: 'sticky', top: 0, zIndex: 1000, width: '100%' }}>
      <Row gutter={16} style={{ marginLeft: '-40px', marginRight: '-40px' }}>
        <Col className="gutter-row" span={6}>
          <img src={akijoLogo} alt="logo" style={{ width: isMobile ? '120px' : '150px', verticalAlign: 'middle' }} />
        </Col>
        <Desktop>
          <Col flex={1}>
            {/* <Search
              placeholder="検索"
              allowClear
              enterButton
              size="large"
              style={{ verticalAlign: 'middle' }}
              onSearch={() => {}}
            /> */}
          </Col>
        </Desktop>
        <Col className="gutter-row" span={isMobile ? 18 : 6}>
          <Row justify="end">
            <Space size="middle">
              <Dropdown menu={{ items }}>
                <Button icon={<UserOutlined />} shape="circle" />
              </Dropdown>
            </Space>
          </Row>
        </Col>
      </Row>
    </Header>
  )
}
