import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import '@aws-amplify/ui-react/styles.css' // default theme
import { Authenticator, translations } from '@aws-amplify/ui-react'
import { ConfigProvider } from 'antd'
import locale from 'antd/locale/ja_JP'
import { AwsConfigAuth } from './auth'
import { Amplify, I18n } from 'aws-amplify'
import { Provider } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import GlobalStyles from './styles/GlobalStyle'
import lightTheme from './styles/themes/light'

import App from './App'
import store from './store'

Amplify.configure(AwsConfigAuth)

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <ThemeProvider theme={lightTheme}>
      <GlobalStyles />
      <Provider store={store}>
        <ConfigProvider
          locale={locale}
          theme={{
            token: {
              colorPrimary: '#1677FF',
            },
            components: {
              Input: {
                colorPrimary: '#1677FF',
                colorPrimaryHover: '#1677FF',
                colorPrimaryBorder: '#1677FF',
              },
            },
          }}
        >
          <Authenticator.Provider>
            <App />
          </Authenticator.Provider>
        </ConfigProvider>
      </Provider>
    </ThemeProvider>
  </React.StrictMode>,
)

I18n.putVocabularies(translations)
I18n.setLanguage('ja')

const dict = {
  ja: {
    'Password': 'パスワード（半角英数字で、大文字・小文字・数字を1文字以上を含めて8文字以上で入力してください）',
    'Forgot your password?': 'パスワードを忘れた場合',
    'Reset password': 'パスワードをリセット',
    'Code': 'コード',
    'Email': 'メールアドレス',
    'Enter your email': 'メールアドレスを入力',
    'No account?': 'アカウントを持っていない場合',
    'Create account': 'サインアップ',
    'Back to Sign In': 'サインインに戻る',
    'Username *': 'ユーザ名 *',
    'Enter your username': 'ユーザ名を入力',
    'Confirmation Code': '確認コード',
    'Enter your code': '確認コードを入力',
    'Lost your code?': '確認コードが届かない場合',
    'Resend Code': '再送する',
    'Verification code': '検証コード',
    'Enter code': '検証コードを入力',
    'New password': '新しいパスワード',
    'Enter your new password': '新しいパスワードを入力',
    'Your passwords must match': '確認用のパスワードが違います',
    'User does not exist.': 'ユーザーが存在しません',
    'Incorrect username or password.': 'ユーザー名またはパスワードが違います',
    'User is not confirmed.': 'ユーザーは検証されていません',
    'User already exists': 'ユーザーは既に存在します',
    'Invalid verification code provided, please try again.': '指定された確認コードが無効です。もう一度お試しください',
    'Invalid password format': 'パスワードのフォーマットが不正です',
    'Account recovery requires verified contact information': 'アカウントの復旧には確認済みの連絡先情報が必要です',
    'Invalid phone number format': '不正な電話番号フォーマットです。 電話番号は次のフォーマットで入力してください: +12345678900',
    'An account with the given email already exists.': 'そのメールアドレスは既に存在します',
    'Username cannot be empty': 'ユーザー名は必須です',
    'Password attempts exceeded': 'ログイン試行回数が上限に達しました',
    'Attempt limit exceeded, please try after some time.': '試行制限を超過しました。しばらくしてからもう一度お試しください',
    'Username/client id combination not found.': 'ユーザーが存在しません',
    'CUSTOM_AUTH is not enabled for the client.': 'パスワードは必須です', // 本来の意味とは異なるが、パスワード未入力時に発生するのでこの訳としている
    'Password did not conform with policy: Password not long enough': 'パスワードは8文字以上を入力してください (8文字以上の大文字小文字を含む英数字)', // 適宜修正
    'Password did not conform with policy: Password must have uppercase characters': 'パスワードには大文字を含めてください (8文字以上の大文字小文字を含む英数字)', // 適宜修正
    'Password did not conform with policy: Password must have lowercase characters': 'パスワードには小文字を含めてください (8文字以上の大文字小文字を含む英数字)', // 適宜修正
    'Password did not conform with policy: Password must have numeric characters': 'パスワードには数字を含めてください (8文字以上の大文字小文字を含む英数字)', // 適宜修正
    "1 validation error detected: Value at 'password' failed to satisfy constraint: Member must have length greater than or equal to 6": 'パスワードは8文字以上、大文字小文字を含む英数字を指定してください', // 適宜修正。本来の意味とは異なるがこれで明示している。
    "2 validation errors detected: Value at 'password' failed to satisfy constraint: Member must have length greater than or equal to 6; Value at 'password' failed to satisfy constraint: Member must satisfy regular expression pattern: ^[\S]+.*[\S]+$": 'パスワードは8文字以上、大文字小文字を含む英数字を指定してください', // 適宜修正。本来の意味とは異なるがこれで明示している。
    'Temporary password has expired and must be reset by an administrator.': '一時パスワードは無効です。管理者によるリセットが必要です',
    "1 validation error detected: Value null at 'attributeName' failed to satisfy constraint: Member must not be null": '入力チェックエラー、必須項目がNULLです',
    'Invalid code received for user': '無効なコードです', // TOTPでのトークンに誤りがある
    'Invalid session for the user, session is expired.' : '無効なセッション、セッションは有効期限切れです。ログインからやり直してください' // ログインセッション無効です、ログインからやり直し
  },
}

I18n.putVocabularies(dict)