import { getRequest, patchRequest, postRequest } from './apiBase'
import { FetchCitiesPayload } from './models/cityModel'
import { FetchFacilitiesPayload, PostFacilitiesPayload } from './models/facilityModel'
import { PatchMePayload } from './models/userModel'

// me
export const fetchMe = () => getRequest('me')
export const patchMe = (payload: PatchMePayload) => patchRequest(`users/${payload.user_info.id}/profile`, payload.user_info)

// prefectures
export const fetchPrefectures = () => getRequest('prefectures')

// cities
export const fetchCities = (payload: FetchCitiesPayload) => getRequest(`cities?area=${payload.prefecture_id}`)

// medicals
export const fetchMedicals = () => getRequest('medicals')

// vacancies
export const fetchVacancies = () => getRequest('vacancies')

// facilities
export const postFacilities = (payload: PostFacilitiesPayload) => postRequest('facilities/', payload.facility)
export const fetchFacilities = (payload: FetchFacilitiesPayload) =>
  getRequest('facilities/', () => {
    let queryParams = `?pref_id=${payload.facility.pref_id}&city_ids=${payload.facility.city_ids}`
    if (payload.facility.kind) {
      queryParams += `&kind=${payload.facility.kind}`
    }
    if (payload.facility.category_1) {
      queryParams += `&category_1=${payload.facility.category_1}`
    }
    if (payload.facility.category_2) {
      queryParams += `&category_2=${payload.facility.category_2}`
    }
    if (payload.facility.category_3) {
      queryParams += `&category_3=${payload.facility.category_3}`
    }
    if (payload.facility.category_4) {
      queryParams += `&category_4=${payload.facility.category_4}`
    }
    return queryParams
  })
