import { workerSaga } from '@/saga/workerSaga'
import { all, call, takeLatest } from 'typed-redux-saga'

import { setMe } from '@/store/actions/userAction'
import { FETCH_ME, PATCH_ME } from '@/saga/actionTypes'
import { fetchMe, patchMe } from '@/saga/apis'
import { PatchMe } from '../models/userModel'

function* fetchMeSaga() {
  yield* workerSaga({
    actionName: FETCH_ME,
    callMethod: () => call(fetchMe),
    afterMethod: responseData => setMe({ me: responseData }),
    isBackground: true,
  })
}

function* patchMeSaga(action: PatchMe) {
  yield* workerSaga({
    actionName: PATCH_ME,
    callMethod: () => call(patchMe, action.payload),
    afterMethod: responseData => setMe({ me: { user_info: responseData } }),
    successMessage: 'プロフィールを設定しました！',
  })
}

function* userSaga() {
  yield* all([takeLatest(FETCH_ME, fetchMeSaga), takeLatest(PATCH_ME, patchMeSaga)])
}

export default userSaga
