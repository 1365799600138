// request
export const REQUEST_PENDING = 'REQUEST_PENDING'
export const REQUEST_SUCCESS = 'REQUEST_SUCCESS'
export const REQUEST_FAILURE = 'REQUEST_FAILURE'

// me
export const SET_ME = 'SET_ME'

// prefectures
export const SET_PREFECTURES = 'SET_PREFECTURES'

// cities
export const SET_CITIES = 'SET_CITIES'

// medicals
export const SET_MEDICAL = 'SET_MEDICAL'

// vacancies
export const SET_VACANCY = 'SET_VACANCY'

// facilities
export const SET_FACILITIES = 'SET_FACILITIES'
