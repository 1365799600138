import { ReactNode } from 'react'
import { useMediaQuery } from 'react-responsive'

interface ResponsiveProps {
  children: ReactNode
}

export const Mobile = ({ children }: ResponsiveProps) => {
  const isMobile = useMediaQuery({ maxWidth: 767 })
  return isMobile ? children : null
}

export default Mobile
