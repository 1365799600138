import { SET_CITIES } from '@/store/actionTypes'
import { CityActions, CityState } from '@/store/models/cityModel'

const initialState: CityState = {
  cities: [],
}

export default (state = initialState, action: CityActions): CityState => {
  switch (action.type) {
    case SET_CITIES:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return {
        ...state,
      }
  }
}
