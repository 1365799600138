import { FetchCities } from '@/saga/models/cityModel'
import { workerSaga } from '@/saga/workerSaga'
import { all, call, takeLatest } from 'typed-redux-saga'

import { setCities } from '@/store/actions/cityAction'
import { FETCH_CITIES } from '@/saga/actionTypes'
import { fetchCities } from '@/saga/apis'

function* fetchCitiesSaga(action: FetchCities) {
  yield* workerSaga({
    actionName: FETCH_CITIES,
    callMethod: () => call(fetchCities, action.payload),
    afterMethod: responseData => setCities({ cities: responseData }),
    isBackground: true,
  })
}

function* citySaga() {
  yield* all([takeLatest(FETCH_CITIES, fetchCitiesSaga)])
}

export default citySaga
