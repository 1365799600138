import { css } from 'styled-components'

export const Community = css`
  .community-card {
    width: 300px;
    .ant-card-actions {
      span:hover {
        color: unset !important;
      }
    }
  }
  .community-card.mobile {
    width: 100%;
  }
  .community-tab-container {
    height: calc(100% - 10px);
    margin: 10px 10px 0;

    & > div {
      height: 100%;
    }

    .ant-tabs-content-holder,
    .ant-tabs-content,
    .ant-tabs-tabpane {
      overflow: hidden;
      height: 100%;
    }

    #scrollableDiv {
      overflow: auto;
      height: 100%;
    }
  }
  .community-tab-container.mobile {
    margin: 0;
  }
`
