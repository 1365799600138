import { PendingStatus, RequestActions, RequestState } from '@/store/models/requestModel'

import { REQUEST_FAILURE, REQUEST_PENDING, REQUEST_SUCCESS } from '@/store/actionTypes'

const initialState: RequestState = {
  pending: PendingStatus.None,
  action: '',
  status: 0,
  message: null,
  isBackground: false,
}

export default (state = initialState, action: RequestActions): RequestState => {
  switch (action.type) {
    case REQUEST_PENDING:
      return {
        ...state,
        ...action.payload,
      }
    case REQUEST_SUCCESS:
      return {
        ...state,
        ...action.payload,
      }
    case REQUEST_FAILURE:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return {
        ...state,
      }
  }
}
