import { combineReducers } from 'redux'

import cityReducer from './reducers/cityReducer'
import facilityReducer from './reducers/facilityReducer'
import medicalReducer from './reducers/medicalReducer'
import prefectureReducer from './reducers/prefectureReducer'
import requestReducer from './reducers/requestReducer'
import userReducer from './reducers/userReducer'
import vacancyReducer from './reducers/vacancyReducer'

const rootReducer = combineReducers({
  request: requestReducer,
  user: userReducer,
  prefecture: prefectureReducer,
  city: cityReducer,
  medical: medicalReducer,
  vacancy: vacancyReducer,
  facility: facilityReducer,
})

export type AppState = ReturnType<typeof rootReducer>

export default rootReducer
