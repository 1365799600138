import { REQUEST_FAILURE, REQUEST_PENDING, REQUEST_SUCCESS } from '@/store/actionTypes'

export enum PendingStatus {
  None,
  Pending,
  Completed,
}

export interface RequestState {
  pending: PendingStatus
  action: string
  status: number
  message: string | null
  isBackground: boolean
}

export interface RequestPendingPayload {
  action: string
  pending?: number
  isBackground?: boolean
}

export interface RequestSuccessPayload {
  action: string
  status: number
  message?: string
}

export interface RequestFailurePayload {
  action: string
  status: number
  message: string
}

export interface RequestPending {
  type: typeof REQUEST_PENDING
  payload: RequestPendingPayload
}

export type RequestSuccess = {
  type: typeof REQUEST_SUCCESS
  payload: RequestSuccessPayload
}

export type RequestFailure = {
  type: typeof REQUEST_FAILURE
  payload: RequestFailurePayload
}

export type RequestActions = RequestPending | RequestSuccess | RequestFailure
