import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

const getTitle = (pathname: string) => {
  switch (pathname) {
    case '/communities':
      return 'コミュニティ一覧'
    case '/events':
      return 'イベント一覧'
    case '/users':
      return 'メンバー一覧'
    default:
      return ''
  }
}

export const usePageTitle = () => {
  const location = useLocation()
  const [title, setTitle] = useState('')

  useEffect(() => {
    setTitle(getTitle(location.pathname))
  }, [location])

  return title
}
