import { workerSaga } from '@/saga/workerSaga'
import { all, call, takeLatest } from 'typed-redux-saga'

import { setVacancy } from '@/store/actions/vacancyAction'
import { FETCH_VACANCIES } from '@/saga/actionTypes'
import { fetchVacancies } from '@/saga/apis'

function* fetchVacancysSaga() {
  yield* workerSaga({
    actionName: FETCH_VACANCIES,
    callMethod: () => call(fetchVacancies),
    afterMethod: responseData => setVacancy({ vacancies: responseData }),
    isBackground: true,
  })
}

function* vacancySaga() {
  yield* all([takeLatest(FETCH_VACANCIES, fetchVacancysSaga)])
}

export default vacancySaga
