import { SET_ME } from '@/store/actionTypes'
import { UserActions, UserState } from '@/store/models/userModel'

const initialState: UserState = {
  user_info: {
    id: '',
    name: '',
    email: '',
    phone_number: '',
    kind: -1,
    created_at: '',
    updated_at: '',
    place: '',
    department: '',
    occupation: ''
  },
}

export default (state = initialState, action: UserActions): UserState => {
  switch (action.type) {
    case SET_ME:
      return {
        ...state,
        user_info: action.payload.me.user_info,
      }
    default:
      return {
        ...state,
      }
  }
}
