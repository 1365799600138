import { workerSaga } from '@/saga/workerSaga'
import { all, call, takeLatest } from 'typed-redux-saga'

import { setMedical } from '@/store/actions/medicalAction'
import { FETCH_MEDICALS } from '@/saga/actionTypes'
import { fetchMedicals } from '@/saga/apis'

function* fetchMedicalsSaga() {
  yield* workerSaga({
    actionName: FETCH_MEDICALS,
    callMethod: () => call(fetchMedicals),
    afterMethod: responseData => setMedical({ medical: responseData }),
    isBackground: true,
  })
}

function* medicalSaga() {
  yield* all([takeLatest(FETCH_MEDICALS, fetchMedicalsSaga)])
}

export default medicalSaga
