import { Action } from 'redux'
import { CallEffect } from 'redux-saga/effects'
import { setRequestFailure, setRequestPending, setRequestSuccess } from '@/store/actions/requestAction'
import { PendingStatus } from '@/store/models/requestModel'
import { put } from 'typed-redux-saga'
import { SagaGenerator } from 'typed-redux-saga/dist'

import { IResponse } from './apiBase'

export interface workerSagaProps {
  actionName: string
  callMethod: () => SagaGenerator<IResponse, CallEffect<IResponse>>
  successMessage?: string
  isBackground?: boolean
  afterMethod?: (responseData: any) => Action
}

export function* workerSaga(props: workerSagaProps) {
  const { actionName, callMethod, afterMethod, successMessage, isBackground } = props
  try {
    yield* put(
      setRequestPending({ pending: PendingStatus.Pending, isBackground: isBackground || false, action: actionName }),
    )
    const response = yield* callMethod()
    if (response.status !== 200 && response.status !== 201) {
      yield* put(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        setRequestFailure({ message: response.data.message, status: response.status, action: actionName }),
      )
      return
    }
    /* eslint-disable @typescript-eslint/no-unsafe-member-access */
    yield* put(
      setRequestSuccess({
        message: successMessage || response.data.message,
        status: response.status,
        action: actionName,
      }),
    )
    /* eslint-enable */
    if (afterMethod) yield* put(afterMethod(response.data))
  } catch (e: any) {
    /* eslint-disable @typescript-eslint/no-unsafe-member-access */
    yield* put(
      setRequestFailure({
        message: e.response.data.message || e.message,
        status: e.response.status,
        action: actionName,
      }),
    )
    /* eslint-enable */
  }
}
