import { REQUEST_FAILURE, REQUEST_PENDING, REQUEST_SUCCESS } from '@/store/actionTypes'
import {
  PendingStatus,
  RequestFailure,
  RequestFailurePayload,
  RequestPending,
  RequestPendingPayload,
  RequestSuccess,
  RequestSuccessPayload,
} from '@/store/models/requestModel'

export const setRequestPending = (payload: RequestPendingPayload): RequestPending => ({
  type: REQUEST_PENDING,
  payload: { ...{ pending: PendingStatus.Pending, isBackground: false }, ...payload },
})

export const setRequestSuccess = (payload: RequestSuccessPayload): RequestSuccess => ({
  type: REQUEST_SUCCESS,
  payload: { ...{ message: '', pending: PendingStatus.Completed }, ...payload },
})

export const setRequestFailure = (payload: RequestFailurePayload): RequestFailure => ({
  type: REQUEST_FAILURE,
  payload: { ...{ pending: PendingStatus.Completed }, ...payload },
})
