import { ReactNode } from 'react'
import { useMediaQuery } from 'react-responsive'

interface ResponsiveProps {
  children: ReactNode
}

export const Desktop = ({ children }: ResponsiveProps) => {
  const isDesktop = useMediaQuery({ minWidth: 768 })
  return isDesktop ? children : null
}

export default Desktop
