import { SET_FACILITIES } from '@/store/actionTypes'
import { FacilityActions, FacilityState } from '@/store/models/facilityModel'

const initialState: FacilityState = {
  facilities: [],
}

export default (state = initialState, action: FacilityActions): FacilityState => {
  switch (action.type) {
    case SET_FACILITIES:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return {
        ...state,
      }
  }
}
