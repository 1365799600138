import { Auth } from 'aws-amplify'
import axios, { AxiosResponse } from 'axios'

export const baseURL = `${import.meta.env.VITE_API || 'http://localhost:8000'}/api`

const newApiInstance = async () => {
  const attributes: any = await Auth.currentSession()
  const jwtToken = attributes.idToken.jwtToken as string
  return axios.create({
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
    baseURL,
    withCredentials: true,
    validateStatus: status => status < 500,
  })
}

export interface IResponse {
  data: any
  status: number
}

/* eslint-disable @typescript-eslint/no-unsafe-member-access */
export const getRequest = async (path: string, getParams?: () => string): Promise<IResponse> => {
  const apiInstance = await newApiInstance()
  const params = getParams ? getParams() : ''
  const response = await apiInstance.get<AxiosResponse, IResponse>(`${path}${params}`)
  if (response.status === 401) {
    Auth.signOut()
  }
  return response
}

export const getRequestBlob = async (path: string): Promise<IResponse> => {
  const apiInstance = await newApiInstance()
  return await apiInstance.get<AxiosResponse, IResponse>(path, { responseType: 'blob' })
}

export const postRequest = async (path: string, payload: any): Promise<IResponse> => {
  const apiInstance = await newApiInstance()
  return await apiInstance.post<AxiosResponse, IResponse>(path, payload)
}

export const postFormdata = async (path: string, formData: FormData): Promise<IResponse> => {
  const apiInstance = await newApiInstance()
  return await apiInstance.post<AxiosResponse, IResponse>(path, formData, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  })
}

export const patchRequest = async (path: string, payload: any): Promise<IResponse> => {
  const apiInstance = await newApiInstance()
  return await apiInstance.patch<AxiosResponse, IResponse>(path, payload)
}

export const deleteRequest = async (path: string): Promise<IResponse> => {
  const apiInstance = await newApiInstance()
  return await apiInstance.delete<AxiosResponse, IResponse>(path)
}
/* eslint-enable */