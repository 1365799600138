import { SET_PREFECTURES } from '@/store/actionTypes'
import { IPrefecture, PrefectureActions, PrefectureState } from '@/store/models/prefectureModel'

const initialState: PrefectureState = {
  prefectures: [],
}

export default (state = initialState, action: PrefectureActions): PrefectureState => {
  switch (action.type) {
    case SET_PREFECTURES:
      return {
        ...state,
        prefectures: Object.keys(action.payload.prefectures)
          .sort((a, b) => (Number(a) < Number(b) ? -1 : 1))
          .map(
            x =>
              ({
                id: x,
                name: action.payload.prefectures[x],
              }) as IPrefecture,
          ),
      }
    default:
      return {
        ...state,
      }
  }
}
