import { FormOutlined, MenuOutlined, SearchOutlined } from '@ant-design/icons'
import { Button, Col, Drawer, Menu, MenuProps, Row, Typography } from 'antd'
import Desktop from '@/components/templates/responsive/Desktop'
import Mobile from '@/components/templates/responsive/Mobile'
import { useEffect, useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { usePageTitle } from '@/utils/usePageTitle'

const { Title } = Typography

const enum NavKeys {
  Provider = 'provider',
  Search = 'search',
  Users = 'users',
}

export const LayoutMenu = () => {
  const [current, setCurrent] = useState('')
  const [showDrawer, setShowDrawer] = useState(false)
  const [collapsed, setCollapsed] = useState(false)
  const location = useLocation()
  const pageTitle = usePageTitle()

  useEffect(() => {
    setShowDrawer(false)
    setTimeout(() => {
      setShowDrawer(true)
    }, 200)
    return () => {
      setCollapsed(false)
    }
  }, [location])

  useEffect(() => {
    let key = ''
    if (location.pathname.includes(NavKeys.Provider)) {
      key = NavKeys.Provider
    } else if (location.pathname.includes(NavKeys.Search)) {
      key = NavKeys.Search
    }
    setCurrent(key)
  }, [location])

  const onClick: MenuProps['onClick'] = e => {
    setCurrent(e.key)
  }

  const items: MenuProps['items'] = [
    {
      label: (
        <NavLink to="/provider">
          {({ isActive }) => <span className={isActive ? 'active' : ''}>空き情報を提供</span>}
        </NavLink>
      ),
      key: NavKeys.Provider,
      icon: <FormOutlined />,
    },
    {
      label: (
        <NavLink to="/search">
          {({ isActive }) => <span className={isActive ? 'active' : ''}>空き情報を検索</span>}
        </NavLink>
      ),
      key: NavKeys.Search,
      icon: <SearchOutlined />,
    },
  ]

  const toggleCollapsed = () => {
    setShowDrawer(true)
    setCollapsed(!collapsed)
  }

  const handleMenuClose = () => {
    setCollapsed(false)
  }

  return (
    <>
      <Desktop>
        <Menu
          style={{ position: 'sticky', top: 64, zIndex: 999 }}
          onClick={onClick}
          selectedKeys={[current]}
          mode="horizontal"
          items={items}
        />
      </Desktop>
      <Mobile>
        <Row
          align="middle"
          justify="space-between"
          style={{
            position: 'sticky',
            top: 64,
            zIndex: 999,
            height: '44px',
            paddingLeft: '15px',
            paddingRight: '15px',
          }}
        >
          {showDrawer && (
            <>
              <Col>
                <Title style={{ padding: 0, margin: 0 }} level={4}>
                  {pageTitle}
                </Title>
              </Col>
              <Col>
                <Button onClick={toggleCollapsed}>
                  <MenuOutlined />
                </Button>
              </Col>
            </>
          )}
        </Row>
        {showDrawer && (
          <Drawer title="メニュー" placement="right" open={collapsed} onClose={handleMenuClose}>
            <Menu selectedKeys={[current]} mode="vertical" items={items} />
          </Drawer>
        )}
      </Mobile>
    </>
  )
}
