import { Flex, Spin } from 'antd'
import React, { Suspense } from 'react'
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom'

// import AuthPage from './components/pages/auth/AuthPage'
import DefaultPage from '@/components/pages/DefaultPage'
import RestrictedPage from '@/components/pages/restricted/RestrictedPage'

// Error
const Error404 = React.lazy(() => import('@/components/pages/Error404'))

// Restricted
const Provider = React.lazy(() => import('@/components/pages/restricted/provider/ProviderPage'))
const Search = React.lazy(() => import('@/components/pages/restricted/search/SearchPage'))
const SearchResultList = React.lazy(() => import('@/components/pages/restricted/search/SearchResultListPage'))
const SearchResult = React.lazy(() => import('@/components/pages/restricted/search/SearchResultPage'))
const Profile = React.lazy(() => import('@/components/pages/restricted/profile/ProfilePage'))

const boxStyle: React.CSSProperties = {
  width: '100%',
  height: 200,
}

const App: React.FC = () => (
  <Router>
    <Suspense
      fallback={
        <Flex style={boxStyle} justify="center" align="center">
          <Spin size="large" />
        </Flex>
      }
    >
      <Routes>
        <Route path="/" element={<Navigate to="/provider" />} />
        <Route path="/" element={<DefaultPage />}>
          <Route element={<RestrictedPage />}>
            <Route path="provider" element={<Provider />} />
            <Route path="search" element={<Search />} />
            <Route path="search_result" element={<SearchResultList />} />
            <Route path="search_result/:id" element={<SearchResult />} />
            <Route path="profile" element={<Profile />} />
          </Route>
        </Route>
        <Route path="*" element={<Error404 />} />
      </Routes>
    </Suspense>
  </Router>
)

export default App
