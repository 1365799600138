import '@aws-amplify/ui-react/styles.css'

import { withAuthenticator, WithAuthenticatorProps } from '@aws-amplify/ui-react'
import { Layout, message } from 'antd'
import { LayoutFooter } from '@/components/organisms/layout/LayoutFooter'
import { LayoutHeader } from '@/components/organisms/layout/LayoutHeader'
import { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Outlet, useNavigate } from 'react-router-dom'
import { fetchMe } from '@/saga/actions/userAction'
import { getRequestStatusSelector } from '@/store/selectors/requestSelector'

import { PendingStatus } from '@/store/models/requestModel'

// メールアドレスを取得したいとき
// user!.attributes?.email

const DefaultPage = ({ user }: WithAuthenticatorProps) => {
  const navigate = useNavigate()
  const [messageApi, contextHolder] = message.useMessage()
  const isFirstRender = useRef(false)
  const requestStatus = useSelector(getRequestStatusSelector)
  const key = 'request'
  const dispatch = useDispatch()

  useEffect(() => {
    console.warn(user)
    isFirstRender.current = true
    dispatch(fetchMe())
  }, [navigate])

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
      return
    }
    if (requestStatus.pending === PendingStatus.Pending && !requestStatus.isBackground) {
      showLoading()
    }
    if (requestStatus.pending === PendingStatus.Completed) {
      if (requestStatus.status - 200 > 100) {
        showError(requestStatus.message)
      } else if (!requestStatus.isBackground) {
        showSuccess(requestStatus.message)
      }
    }
  }, [requestStatus])

  const showLoading = () => {
    void messageApi.open({
      key,
      type: 'loading',
      content: 'Loading...',
      duration: 0,
    })
  }

  const showSuccess = (msg: string | null) => {
    void messageApi.open({
      key,
      type: 'success',
      content: msg || 'Loaded!',
    })
  }

  const showError = (msg: string | null) => {
    void messageApi.open({
      key,
      type: 'error',
      content: msg || 'network error!',
    })
  }

  /* eslint-disable @typescript-eslint/no-unsafe-member-access */
  return (
    <Layout className="layout">
      {/* eslint-disable @typescript-eslint/no-unsafe-argument */}
      <LayoutHeader />
      {contextHolder}
      <Outlet />
      <LayoutFooter />
    </Layout>
  )
  /* eslint-enable */
}

export default withAuthenticator(DefaultPage, { loginMechanisms: ['email'] })
