import { BoxShadow, Colors, Fonts, FontSizes, LayoutSizes, Radius } from './types'

export const fonts: Fonts = {
  fontFamily: "'Noto Sans JP', sans-serif;",
}

export const fontSizes: FontSizes = {
  baseFontSize: '16px',
  titleFontSize: '18px',
  descriptionFontSize: '14px',
  fontWeight: 'normal',
}

export const colors: Colors = {
  // mainRed: '#F96666',
  // mainGray: '#F9F9F9',
  // mainYellow: '#F9E6A2',
  // mainBrown: '#674747',
  main: '#FF5D5D',
  secondary: '#233D53',
  tertiary: '#C6EBC5',
  background: '#F9F3E6',
  stroke: '#EAEAEA',
  white: '#FFFFFF',
}

export const layoutSizes: LayoutSizes = {
  sidenavWidth: '240px',
  breadcrumbHeight: '48px',
}

export const radius: Radius = {
  cardRadius: '5px',
}

export const boxShadow: BoxShadow = {
  boxShadow: '0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%)',
}

export const defaultTheme = {
  colors,
  fonts,
  fontSizes,
  layoutSizes,
  radius,
  boxShadow,
}

export default defaultTheme
