import { applyMiddleware, createStore, Store } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import logger from 'redux-logger'
import createSagaMiddleware from 'redux-saga'
import rootSaga from './saga/rootSaga'
import rootReducer from './store/rootReducer'

const sagaMiddleware = createSagaMiddleware()

const store: Store = createStore(rootReducer, composeWithDevTools(applyMiddleware(sagaMiddleware, logger)))

sagaMiddleware.run(rootSaga)

export default store
