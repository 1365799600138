import { FetchFacilities,PostFacilities } from '@/saga/models/facilityModel'
import { workerSaga } from '@/saga/workerSaga'
import { setFacilities } from '@/store/actions/facilityAction'
import { all, call, takeLatest } from 'typed-redux-saga'

import { FETCH_FACILITIES,POST_FACILITIES } from '@/saga/actionTypes'
import { fetchFacilities, postFacilities } from '@/saga/apis'

function* postFacilitiesSaga(action: PostFacilities) {
  yield* workerSaga({
    actionName: POST_FACILITIES,
    callMethod: () => call(postFacilities, action.payload),
    successMessage: '空き情報を登録しました！',
  })
}

function* fetchFacilitiesSaga(action: FetchFacilities) {
  yield* workerSaga({
    actionName: FETCH_FACILITIES,
    callMethod: () => call(fetchFacilities, action.payload),
    afterMethod: responseData => setFacilities({ facilities: responseData }),
    isBackground: true,
  })
}

function* facilitySaga() {
  yield* all([takeLatest(POST_FACILITIES, postFacilitiesSaga), takeLatest(FETCH_FACILITIES, fetchFacilitiesSaga)])
}

export default facilitySaga
