import { workerSaga } from '@/saga/workerSaga'
import { all, call, takeLatest } from 'typed-redux-saga'

import { setPrefectures } from '@/store/actions/prefectureAction'
import { FETCH_PREFECTURES } from '@/saga/actionTypes'
import { fetchPrefectures } from '@/saga/apis'

function* fetchPrefecturesSaga() {
  yield* workerSaga({
    actionName: FETCH_PREFECTURES,
    callMethod: () => call(fetchPrefectures),
    afterMethod: responseData => setPrefectures({ prefectures: responseData }),
    isBackground: true,
  })
}

function* prefectureSaga() {
  yield* all([takeLatest(FETCH_PREFECTURES, fetchPrefecturesSaga)])
}

export default prefectureSaga
