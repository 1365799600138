import { css } from 'styled-components'

export const Chat = css`
  .chat-item {
    .ant-list-item-meta-description {
      color: #000 !important;
    }
    .datetime {
      color: #a2a2a2;
      font-size: 10px;
    }
  }
`
